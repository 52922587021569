import axiosInstance from '../http';
import { API } from '@/shared/constants/api';
import ServiceBuilder from '@/shared/lib/utils/service';
const updateUserDetails = async ({ userId, format, ...params }) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.updateUserDetails, {
        id: userId,
        FileFormat: format,
        ...params,
    }, {
        timeout: 30000,
    });
    return response;
});
const getUserTable = async (userId) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.get(API.userTable, {
        params: { userId },
    });
    return response;
});
const getUserPicture = async (userId) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.get(API.userPicture, {
        params: { id: userId },
    });
    return response;
});
const deleteUser = async (id, password) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.delete(API.deleteUser, {
        params: { id },
        data: { password },
    });
    return response;
});
const blockUser = async (id) => ServiceBuilder.serviceRequest(() => axiosInstance.post(`${API.blockUser}/${id}`, {}, { withCredentials: true }));
const UserService = {
    updateUserDetails,
    getUserTable,
    deleteUser,
    getUserPicture,
    blockUser,
};
export default UserService;
