import { useNavigate } from 'react-router-dom';
import { useNotificationHubReceive, useRoomDisconnect } from '@/shared/lib/hooks';
import { HUB_METHODS } from '@/shared/constants/hub';
import { ROUTES } from '@/shared/constants/routes';
export const useBlockedHandler = () => {
    const navigate = useNavigate();
    const disconnect = useRoomDisconnect();
    useNotificationHubReceive(HUB_METHODS.notification.receive.blockedAlert, async () => {
        await disconnect();
        navigate(ROUTES.home.path);
    });
};
