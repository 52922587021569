import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { ContentActions, ContentDescription, ContentHeading, ModalContent } from './styled';
import { modalStore } from '@/shared/model/modal';
import TextElement from '@/shared/ui/TextElement';
import ButtonCustom from '@/shared/ui/ButtonCustom';
const InteractionConfirmation = ({ title, message, onSubmit, onCancel, confirmLabel, width, }) => {
    const handleClose = () => {
        modalStore.close();
        onCancel?.();
    };
    const handleSubmit = () => {
        modalStore.close();
        onSubmit();
    };
    return (_jsxs(ModalContent, { width: width, children: [_jsx(ContentHeading, { children: _jsx(TextElement, { fontSize: 24, fontWeight: 600, component: 'h6', children: title }) }), _jsx(ContentDescription, { children: _jsx(TextElement, { fontSize: 16, children: message }) }), _jsxs(ContentActions, { children: [_jsx(ButtonCustom, { variant: 'outlined', onClick: handleClose, height: 48, width: 162, children: "Nevermind" }), _jsx(ButtonCustom, { variant: 'contained', color: 'primary', height: 48, width: 162, onClick: handleSubmit, children: confirmLabel })] })] }));
};
export default memo(InteractionConfirmation);
