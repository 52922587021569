import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import FriendsLoader from './model/FriendsLoader';
import useSpeedDatingStartedHandler from './lib/hooks/useSpeedDatingStartedHandler';
import { useBlockedHandler } from './lib/hooks/useBlockedHandler';
import { headerStore } from '@/shared/model/header';
import ChatAside from '@/widgets/ChatAside';
import FriendsAside from '@/widgets/FriendsAside';
import { userStore } from '@/shared/model/user';
const AsideMenu = observer(() => {
    const { pathname } = useLocation();
    const { user: { role }, } = userStore;
    useBlockedHandler();
    useSpeedDatingStartedHandler(role);
    useLayoutEffect(() => {
        headerStore.closeAllChats();
    }, [pathname]);
    return (_jsx(FriendsLoader, { children: _jsxs(_Fragment, { children: [_jsx(ChatAside, {}), _jsx(FriendsAside, {})] }) }));
});
export default memo(AsideMenu);
