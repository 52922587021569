import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, ListItem, Popover } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { FriendsActionsList, ActionsList, ActionButton, FriendActions, FriendContainer, OnClickWrapper, OnlineStatus, TextWrapper, UserContainer, UserName, UserRoom, UnreadIcon, } from './styled';
import InteractionNotification from '@/entities/notifications/InteractionNotification';
import FriendService from '@/shared/api/services/FriendService';
import { AddVideoIcon, MoreIcon, ProfileDeleteIcon } from '@/shared/assets/icons';
import { HUB_METHODS } from '@/shared/constants/hub';
import { ROUTES } from '@/shared/constants/routes';
import { usePopoverAnchor, useResizeObserver } from '@/shared/lib/hooks';
import { friendStore } from '@/shared/model/friends';
import { modalStore } from '@/shared/model/modal';
import { roomStore } from '@/shared/model/room';
import { userStore } from '@/shared/model/user';
import CustomTooltip from '@/shared/ui/CustomTooltip';
import ProfileAvatar from '@/shared/ui/ProfileAvatar';
import NotificationHub from '@/shared/api/hubs/NotificationHub';
import InteractionConfirmation from '@/entities/notifications/InteractionConfirmation';
export const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};
const Friend = observer(({ onOpen, friendId, profilePicture, name, online, lastOnlinePlace, unread, }) => {
    const { room } = roomStore;
    const { countUsers, maxCapacity, isSpeedDating } = room;
    const { friends, pendingInvites, newFriends } = friendStore;
    const { user, userTable } = userStore;
    const { pathname } = useLocation();
    const params = useParams();
    const roomId = Number(params.roomId);
    const { open: openActions, handleOpen: handleOpenActions, handleClose: handleCloseActions, } = usePopoverAnchor();
    const isRoom = pathname.startsWith(`${ROUTES.rooms.path}/`);
    const isBar = useMemo(() => userTable?.tableName === 'Bar', [userTable]);
    const isTableFull = useMemo(() => {
        if (!userTable)
            return true;
        if (userTable.tableName === 'Stage')
            return true;
        return (userTable.tableName === 'Table' &&
            userTable.seats.length === userTable.seats.filter((seat) => !!seat.userC).length);
    }, [userTable]);
    const isAlreadyInTable = useMemo(() => !!userTable?.seats.some((s) => s.userC === friendId || s.blockedByUserId === friendId), [friendId, userTable?.seats]);
    const isRoomFull = useMemo(() => countUsers === maxCapacity, [countUsers, maxCapacity]);
    const handleCancelInviteClick = useCallback((id) => () => {
        const handleSubmitCancelInvite = async () => {
            await NotificationHub.sendMessage(HUB_METHODS.notification.send.cancelInviteToTable, {
                inviterId: user.id,
                roomId,
                receiverId: id,
            });
            friendStore.removePendingInvite(id);
            handleCloseActions();
        };
        modalStore.open({
            content: (_jsx(InteractionConfirmation, { title: 'Cancel invitation', confirmLabel: 'Yes, cancel', onSubmit: handleSubmitCancelInvite, message: 'Are you sure you want to cancel the invitation? The invited user will be notified that the invitation has been canceled.', width: '540px' })),
        });
    }, [handleCloseActions, roomId, user.id]);
    const handleInviteClick = useCallback((id) => async () => {
        await NotificationHub.sendMessage(HUB_METHODS.notification.send.inviteToTable, {
            inviterId: user.id,
            roomId,
            receiverId: id,
        });
        friendStore.addPendingInvite(id);
        modalStore.open({
            content: _jsx(InteractionNotification, {}),
        });
        handleCloseActions();
    }, [handleCloseActions, user.id, roomId]);
    const handleUnfriend = useCallback(async () => {
        const response = await FriendService.unfriend(friendId, user.id);
        if (response && !isAxiosError(response)) {
            const removedFriends = friends.filter((friend) => friend.id !== friendId);
            friendStore.friends = removedFriends;
        }
        handleCloseActions();
    }, [friendId, friends, handleCloseActions, user.id]);
    const isRequestPending = !!pendingInvites.find((pI) => pI === friendId);
    const isNewFriend = newFriends.some((friend) => friend.id === friendId);
    const disabled = isTableFull || isRoomFull || isBar || isAlreadyInTable;
    const getDisabledTitle = () => {
        if (isBar) {
            return 'You must sit in a table to invite';
        }
        if (isAlreadyInTable) {
            return 'User is already in your table';
        }
        if (isRequestPending) {
            return 'Your invitation is still pending';
        }
        return 'Your table or room is full!';
    };
    const disabledTitle = getDisabledTitle();
    const getInviteFriendTitle = () => {
        if (isRequestPending) {
            return 'Your invitation is still pending';
        }
        return 'Invite to table';
    };
    const inviteFriendTitle = getInviteFriendTitle();
    // TODO: Refactor this -_-
    // In prev implementation on screen resize FriendsActions relocated to top-left corner.
    // RoomPopover have logic to handle this. And now copied here.
    // So needs to refactor and use RoomPopover instead copy-paste like it did right now
    const popoverActions = useRef(null);
    const anchorRef = useRef(null);
    const handleAnchorResize = useCallback(() => {
        if (popoverActions.current) {
            popoverActions.current.updatePosition();
        }
    }, []);
    useResizeObserver(anchorRef.current, handleAnchorResize);
    return (_jsxs(FriendContainer, { children: [_jsxs(OnClickWrapper, { onClick: onOpen, children: [_jsxs(UserContainer, { children: [_jsx(ProfileAvatar, { src: profilePicture, alt: name, height: 55, width: 55 }), _jsx(OnlineStatus, { status: online ? 'online' : 'offline', "aria-label": online ? `${name} is online` : `${name} is offline` })] }), _jsxs(TextWrapper, { children: [_jsx(UserName, { color: online ? 'darkGray' : 'offline', children: name }), online ? _jsx(UserRoom, { children: lastOnlinePlace }) : null] }), isNewFriend && !unread && _jsx(UnreadIcon, {})] }), _jsxs(FriendActions, { children: [isRoom && !isSpeedDating && online ? (_jsx(CustomTooltip, { placement: 'top', title: disabled ? disabledTitle : inviteFriendTitle, disabled: disabled, children: _jsx("span", { children: _jsx(IconButton, { disabled: disabled, color: 'black', onClick: isRequestPending
                                    ? handleCancelInviteClick(friendId)
                                    : handleInviteClick(friendId), children: _jsx(AddVideoIcon, { opacity: isRequestPending ? 0.3 : 1 }) }) }) })) : null, _jsx(IconButton, { color: 'black', "aria-label": 'More', onClick: handleOpenActions, children: _jsx(MoreIcon, {}) })] }), unread && _jsx(UnreadIcon, {}), _jsx("div", { style: {
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                }, ref: anchorRef }), _jsx(Popover, { open: openActions, anchorEl: anchorRef.current, onClose: handleCloseActions, anchorOrigin: anchorOrigin, PaperProps: {
                    elevation: 0,
                    sx: {
                        boxShadow: '0px 2px 2px rgba(33, 37, 41, 0.06), 0px 0px 1px rgba(33, 37, 41, 0.08)',
                    },
                }, action: popoverActions, children: _jsx(FriendsActionsList, { children: _jsx(ActionsList, { children: _jsx(ListItem, { disableGutters: true, children: _jsxs(ActionButton, { color: 'error', onClick: handleUnfriend, children: [_jsx(ProfileDeleteIcon, {}), "Unfriend"] }) }) }) }) })] }));
});
export default memo(Friend);
