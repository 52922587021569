export const HUB_METHODS = {
    interaction: {
        send: {
            message: 'SendMessage',
            streamId: 'SendUpdateStreamId',
            inviteToTable: 'InviteToTable',
            requestToJoin: 'RequestToJoin',
            approveInviteToTable: 'ApproveInviteToTable',
            declineInviteToTable: 'DeclineInviteToTable',
            declineRequestToJoin: 'DeclineRequestToJoin',
            approveRequestToJoin: 'ApproveRequestToJoin',
            checkNotificationStatus: 'CheckNotificationStatus',
            cancelInteraction: 'CancelInteraction',
            leaveTable: 'LeaveFromTable',
            friendRequest: 'SendFriendRequest',
            mute: 'SendMuteAlert',
            videoMute: 'SendVideoMuteAlert',
            moveToSeat: 'MoveToSeat',
            lookAtUser: 'LookAtUser',
            passNote: 'PassNote',
            requestToJoinStage: 'RequestToJoinStage',
            cancelRequestToJoinStage: 'CancelRequestToJoinStage',
            approveRequestToJoinStage: 'ApproveRequestToJoinStage',
            rejectRequestToJoinStage: 'RejectRequestToJoinStage',
            kickUserFromStage: 'KickUserFromStage',
            leaveFromStage: 'LeaveFromStage',
            setSpeedDatingFilters: 'SetSpeedDatingRoomFilters',
            speedDatingLike: 'SpeedDatingLike',
            speedDatingUndoLike: 'SpeedDatingUndoLike',
            setUserAnswer: 'SetUserAnswer',
            newStreamId: 'SetNewStreamId',
            setUserMix: 'SetUserMix',
        },
        receive: {
            message: 'ReceiveMessage',
            tables: 'ReceiveTables',
            roomAccessRejected: 'RejectedRoomAccess',
            inviteToTable: 'InviteToTableReceive',
            requestToJoin: 'RequestToJoinReceive',
            approveInviteToTable: 'ApproveInviteToTableReceive',
            declineInviteToTable: 'DeclineInviteToTableReceive',
            approveRequestToJoin: 'ApproveRequestToJoinReceive',
            declineRequestToJoin: 'DeclineRequestToJoinReceive',
            checkNotificationStatus: 'CheckNotificationStatusReceive',
            leaveTable: 'LeaveFromTableReceive',
            initialSeat: 'InitialSeatReceive',
            moveToSeat: 'MoveToSeatReceive',
            lookedBy: 'LookedBy',
            passNote: 'PassNoteReceive',
            stageList: 'StageList',
            stageListPerformer: 'StageListPerformer',
            rejectToJoinStage: 'RejectRequestToJoinStageReceive',
            speedDatingRoundStarted: 'SpeedDatingRoundStarted',
            speedDatingRoundEnded: 'SpeedDatingRoundEnded',
            speedDatingRoundParticipation: 'SpeedDatingRoundParticipation',
            speedDatingMatch: 'SpeedDatingMatch',
            speedDatingUndoMatch: 'SpeedDatingUndoMatch',
        },
    },
    table: {
        send: {
            messageToGroup: 'SendMessageToGroup',
            muteAlertToGroup: 'SendMuteAlertToGroup',
        },
        receive: {
            message: 'ReceiveMessage',
        },
    },
    notification: {
        send: {
            inviteToTable: 'InviteToTable',
            cancelInviteToTable: 'CancelInviteToTable',
            approveInviteToTable: 'InviteToTableApproved',
            readFriendChat: 'ReadChatAlert',
            checkInviterStatus: 'CheckInviterStatus',
            denyFriendInvite: 'InviteToTableDenied',
        },
        receive: {
            newFriendMessage: 'NewFriendMessageReceive',
            newFriendRequest: 'NewFriendRequestReceive',
            declineFriendRequest: 'DeclineFriendRequestReceive',
            approveFriendRequest: 'ApproveFriendRequestReceive',
            removed: 'RemoveFriendReceive',
            friendBecomeOnline: 'FriendOnlineReceive',
            friendBecomeOffline: 'FriendOfflineReceive',
            onlineFriendsList: 'OnlineFriendsListReceive',
            friendPlace: 'FriendPlaceReceive',
            inviteToTable: 'InviteToTableReceive',
            approveInviteToTable: 'InviteToTableApprovedReceive',
            approveInviteToTableInviter: 'InviteToTableApprovedReceiveInviter',
            logout: 'Logout',
            deployment: 'Deployment',
            roomStateUpdated: 'RoomStateUpdated',
            inviterStatus: 'InviterStatusReceive',
            invites: 'StoredInvitesReceive',
            speedDatingEventStarted: 'SpeedDatingEventStarted',
            speedDatingEventEnded: 'SpeedDatingEventEnded',
            friendsList: 'FriendsList',
            inviteToTableDenied: 'InviteToTableDeniedReceived',
            blockedAlert: 'BlockedAlert',
            inviteToTableCanceled: 'InviteToTableCanceledReceive',
        },
    },
};
export var NotificationHubStatusCode;
(function (NotificationHubStatusCode) {
    NotificationHubStatusCode[NotificationHubStatusCode["Ok"] = 0] = "Ok";
    NotificationHubStatusCode[NotificationHubStatusCode["InviterLeft"] = 1] = "InviterLeft";
    NotificationHubStatusCode[NotificationHubStatusCode["RoomFull"] = 2] = "RoomFull";
    NotificationHubStatusCode[NotificationHubStatusCode["TableFull"] = 3] = "TableFull";
    NotificationHubStatusCode[NotificationHubStatusCode["InviterOffline"] = 4] = "InviterOffline";
    NotificationHubStatusCode[NotificationHubStatusCode["Canceled"] = 5] = "Canceled";
})(NotificationHubStatusCode || (NotificationHubStatusCode = {}));
export var InteractionHubStatusCode;
(function (InteractionHubStatusCode) {
    InteractionHubStatusCode[InteractionHubStatusCode["Ok"] = 0] = "Ok";
    InteractionHubStatusCode[InteractionHubStatusCode["Receiver"] = 1] = "Receiver";
    InteractionHubStatusCode[InteractionHubStatusCode["TableFull"] = 2] = "TableFull";
    InteractionHubStatusCode[InteractionHubStatusCode["InviterLeft"] = 3] = "InviterLeft";
    InteractionHubStatusCode[InteractionHubStatusCode["Canceled"] = 4] = "Canceled";
    InteractionHubStatusCode[InteractionHubStatusCode["RequesterLeave"] = 5] = "RequesterLeave";
    InteractionHubStatusCode[InteractionHubStatusCode["AlreadyInTable"] = 6] = "AlreadyInTable";
    InteractionHubStatusCode[InteractionHubStatusCode["AlreadyHasPendingInteraction"] = 7] = "AlreadyHasPendingInteraction";
    InteractionHubStatusCode[InteractionHubStatusCode["AlreadyHasFriendRequest"] = 8] = "AlreadyHasFriendRequest";
    InteractionHubStatusCode[InteractionHubStatusCode["AlreadyFriends"] = 9] = "AlreadyFriends";
})(InteractionHubStatusCode || (InteractionHubStatusCode = {}));
export var InteractionMessageType;
(function (InteractionMessageType) {
    InteractionMessageType[InteractionMessageType["AdminAssignment"] = 0] = "AdminAssignment";
    InteractionMessageType[InteractionMessageType["UserKicked"] = 1] = "UserKicked";
    InteractionMessageType[InteractionMessageType["RequestToJoin"] = 2] = "RequestToJoin";
    InteractionMessageType[InteractionMessageType["AcceptedRequest"] = 3] = "AcceptedRequest";
    InteractionMessageType[InteractionMessageType["PassedRequest"] = 4] = "PassedRequest";
    InteractionMessageType[InteractionMessageType["GroupMessage"] = 5] = "GroupMessage";
    InteractionMessageType[InteractionMessageType["InviteToTable"] = 6] = "InviteToTable";
    InteractionMessageType[InteractionMessageType["AcceptedInvite"] = 7] = "AcceptedInvite";
    InteractionMessageType[InteractionMessageType["PassedInvite"] = 8] = "PassedInvite";
    InteractionMessageType[InteractionMessageType["SendNote"] = 9] = "SendNote";
    InteractionMessageType[InteractionMessageType["RepliedNote"] = 10] = "RepliedNote";
    InteractionMessageType[InteractionMessageType["PassedNote"] = 11] = "PassedNote";
    InteractionMessageType[InteractionMessageType["PassedReply"] = 12] = "PassedReply";
    InteractionMessageType[InteractionMessageType["StartedEvent"] = 13] = "StartedEvent";
    InteractionMessageType[InteractionMessageType["EndedEvent"] = 14] = "EndedEvent";
})(InteractionMessageType || (InteractionMessageType = {}));
export const INTERACTION_TYPE = {
    inviter: 0,
    requester: 1,
    noteSender: 2,
};
export var SeatStatus;
(function (SeatStatus) {
    SeatStatus[SeatStatus["Ok"] = 0] = "Ok";
    SeatStatus[SeatStatus["Occupied"] = 1] = "Occupied";
    SeatStatus[SeatStatus["ForceLeave"] = 2] = "ForceLeave";
    SeatStatus[SeatStatus["Confirm"] = 3] = "Confirm";
    SeatStatus[SeatStatus["Request"] = 4] = "Request";
})(SeatStatus || (SeatStatus = {}));
