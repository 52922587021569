import { makeAutoObservable } from 'mobx';
import { differenceInYears } from 'date-fns';
import { STORAGE_KEYS } from '@/shared/constants/common';
import { ROLES } from '@/shared/constants/roles';
import StorageService from '@/shared/lib/utils/storage';
import SessionStorageService from '@/shared/lib/utils/sessionStorage';
const nonExistingUser = {
    id: 0,
    email: '',
    resume: '',
    dtBirthDate: new Date(),
    userName: '',
    profilePicture: '',
    profilePictureFormat: '',
    gender: '',
    country: '',
    role: ROLES.user,
    interestedIn: '',
    roomId: 0,
    isAdmin: false,
};
class UserStore {
    _user = StorageService.get(STORAGE_KEYS.user) ?? nonExistingUser;
    _userTable = undefined;
    _userSeatType = undefined;
    _userTableId = 0;
    _userPendingSeatId = null;
    _userPendingSeatManagerC = null;
    _userStreamId = 0;
    _userTableName = 'Bar';
    _userRoomQuestionAnswer = '';
    _userPendingRandomPlacement = false;
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    set userTableName(userTableName) {
        if (this._userTableName !== userTableName) {
            this._userTableName = userTableName;
        }
    }
    get userTableName() {
        return this._userTableName;
    }
    get isTableManager() {
        return this._userTable?.managerId === this._user.id && this._userTableName !== 'Bar';
    }
    get userEmptySeats() {
        return this.userTable?.seats.filter(({ userC }) => userC === 0);
    }
    set userTable(userTable) {
        this._userTable = userTable;
    }
    get userTable() {
        return this._userTable;
    }
    set userSeatType(userSeatType) {
        this._userSeatType = userSeatType;
    }
    get userSeatType() {
        return this._userSeatType;
    }
    set userStreamId(streamId) {
        this._userStreamId = streamId;
    }
    get userStreamId() {
        return this._userStreamId;
    }
    set userPendingRandomPlacement(userPendingRandomPlacement) {
        this._userPendingRandomPlacement = userPendingRandomPlacement;
    }
    get userPendingRandomPlacement() {
        return this._userPendingRandomPlacement;
    }
    get userPendingSeatId() {
        return this._userPendingSeatId;
    }
    set userPendingSeatId(pendingSeatId) {
        this._userPendingSeatId = pendingSeatId;
        if (!pendingSeatId) {
            this._userPendingSeatManagerC = null;
        }
    }
    get userPendingSeatManagerC() {
        return this._userPendingSeatManagerC;
    }
    set userPendingSeatManagerC(userPendingSeatManagerC) {
        this._userPendingSeatManagerC = userPendingSeatManagerC;
    }
    set userTableId(tableId) {
        if (this._userTableId !== tableId) {
            this._userTableId = tableId;
        }
    }
    get userTableId() {
        return this._userTableId;
    }
    get userSeat() {
        return this._userTable?.seats.find((s) => s.userC === this._user.id);
    }
    set user(user) {
        this._user = user;
    }
    get user() {
        return this._user;
    }
    get age() {
        const currentDate = new Date();
        const ageInYears = differenceInYears(currentDate, this._user.dtBirthDate);
        return ageInYears;
    }
    get ageGroup() {
        const currentDate = new Date();
        const ageInYears = differenceInYears(currentDate, this._user.dtBirthDate);
        if (ageInYears < 18)
            return '';
        if (ageInYears < 26)
            return '18-25';
        if (ageInYears < 31)
            return '25-30';
        if (ageInYears < 41)
            return '30-40';
        if (ageInYears < 50)
            return '40-50';
        return '50+';
    }
    get sexualOrientation() {
        if ((this._user.gender === 'Male' && this._user.interestedIn === 'Men') ||
            (this._user.gender === 'Female' && this._user.interestedIn === 'Women')) {
            return 'Gay';
        }
        if ((this._user.gender === 'Male' && this._user.interestedIn === 'Women') ||
            (this._user.gender === 'Female' && this._user.interestedIn === 'Men')) {
            return 'Straight';
        }
        return 'Both';
    }
    set userRoomQuestionAnswer(userRoomQuestionAnswer) {
        this._userRoomQuestionAnswer = userRoomQuestionAnswer;
    }
    get userRoomQuestionAnswer() {
        return this._userRoomQuestionAnswer;
    }
    getSeatByUserId = (userId) => this._userTable?.seats.find((s) => s.userC === userId);
    clear = () => {
        this._userTableId = 0;
        this._userStreamId = 0;
        this._userTable = undefined;
        this._userTableName = 'Bar';
    };
    logout = () => {
        this._user = nonExistingUser;
        SessionStorageService.remove(STORAGE_KEYS.ageGroup);
        StorageService.remove(STORAGE_KEYS.feedbackModalCount);
        StorageService.remove(STORAGE_KEYS.accessToken);
    };
}
export const userStore = new UserStore();
