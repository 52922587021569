import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useId, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { HttpStatusCode, isAxiosError } from 'axios';
import { ActionsForm, CloseButton, Content, RequestSent } from './styled';
import { defaultValues, registerScheme } from './model';
import useFilterFriends from '../../lib/hooks/useFilterFriends';
import FriendService from '@/shared/api/services/FriendService';
import { CloseIcon } from '@/shared/assets/icons';
import { useTimeout } from '@/shared/lib/hooks';
import { modalStore } from '@/shared/model/modal';
import { userStore } from '@/shared/model/user';
import SearchBar from '@/shared/ui/SearchBar';
import SubmitButton from '@/shared/ui/SubmitButton';
import TextElement from '@/shared/ui/TextElement';
import FriendAdded from '@/entities/notifications/FriendAdded';
const AddFriendForm = observer(() => {
    const { user } = userStore;
    const { myFriendsEmails } = useFilterFriends();
    const { id } = user;
    const [requestSent, setRequestSent] = useState(false);
    const [requestDeclined, setRequestDeclined] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [emailError, setEmailError] = useState('');
    const handleClose = useCallback(() => {
        modalStore.close();
        setRequestSent(false);
        setRequestDeclined(false);
    }, []);
    const { handleSubmit, control, formState: { errors, isValid }, reset, } = useForm({
        defaultValues,
        reValidateMode: 'onSubmit',
        resolver: zodResolver(registerScheme),
    });
    useTimeout(() => {
        if (requestSent) {
            setRequestSent(false);
        }
    }, 3000, [requestSent]);
    const submit = useCallback(async (data) => {
        setRequestSent(false);
        setRequestDeclined(false);
        if (!isValid)
            return;
        const { email } = data;
        if (myFriendsEmails.includes(email)) {
            modalStore.open({
                content: (_jsx(FriendAdded, { title: 'Oops!', confirmLabel: 'Continue', message: 'Seems like you are already friends with that user.', width: '520px' })),
            });
            return;
        }
        const response = await FriendService.requestFriend(id, email);
        if (isAxiosError(response)) {
            const { request: { status }, } = response;
            if (status === HttpStatusCode.TooManyRequests) {
                setEmailError('System error');
                return;
            }
            setRequestDeclined(true);
            const responseData = response?.response?.data;
            setResponseMessage(responseData?.Title ?? '');
            return;
        }
        setRequestSent(true);
        reset(defaultValues);
    }, [id, isValid, myFriendsEmails, reset]);
    const requestSentId = useId();
    return (_jsxs(Content, { children: [_jsx(CloseButton, { onClick: handleClose, "aria-label": 'Close', children: _jsx(CloseIcon, {}) }), _jsx(TextElement, { variant: 'h6', textAlign: 'center', fontSize: 24, fontWeight: 600, children: "Add friends" }), _jsx(TextElement, { fontSize: 16, children: "Already know your friend's email? Send them a request!" }), _jsxs(ActionsForm, { onSubmit: handleSubmit(submit), "aria-describedby": requestSent ? requestSentId : undefined, children: [_jsx(Controller, { name: 'email', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(SearchBar, { inputRef: ref, placeholder: 'Friend email', error: !!errors.email || !!emailError, helperText: errors.email?.message ?? emailError, onChange: (event) => {
                                if (requestSent) {
                                    setRequestSent(false);
                                }
                                onChange(event);
                            }, autoComplete: 'off', ...field })) }), _jsx(SubmitButton, { width: 116, height: 40, fontSize: 16, fontWeight: 500, children: "Add friend" })] }), requestSent ? (_jsxs(RequestSent, { id: requestSentId, children: [_jsx(TextElement, { variant: 'h6', fontSize: 24, fontWeight: 600, textAlign: 'center', children: "Request sent!" }), _jsx(TextElement, { fontSize: 16, textAlign: 'center', children: "A request has been sent to this user. You will see them online if they accept your request." })] })) : null, requestDeclined ? (_jsxs(RequestSent, { id: requestSentId, children: [_jsx(TextElement, { variant: 'h6', fontSize: 24, fontWeight: 600, textAlign: 'center', children: "Oops!" }), _jsx(TextElement, { fontSize: 16, textAlign: 'center', children: responseMessage ||
                            'You already have a friend request pending from this user. Please check your friend requests before sending another request.' })] })) : null] }));
});
export default memo(AddFriendForm);
