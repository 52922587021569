import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { memo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchFriends from '../../lib/hooks/useFetchFriends';
import { HUB_METHODS, NotificationHubStatusCode } from '@/shared/constants/hub';
import { useNotificationHubReceive, useRoomDisconnect } from '@/shared/lib/hooks';
import { friendStore } from '@/shared/model/friends';
import AlertNotification from '@/entities/notifications/AlertNotification';
import { modalStore } from '@/shared/model/modal';
import { userStore } from '@/shared/model/user';
import getStatusMessage from '@/widgets/AsideMenu/lib/utils/getStatusMessage';
import { headerStore } from '@/shared/model/header';
import InteractionNotification from '@/entities/notifications/InteractionNotification';
import { ROUTES } from '@/shared/constants/routes';
import NotificationHub from '@/shared/api/hubs/NotificationHub';
import { connectionStore } from '@/shared/model/connection';
import { pendingInteractionsStore } from '@/shared/model/interactions/model/store';
import StorageService from '@/shared/lib/utils/storage';
import { STORAGE_KEYS } from '@/shared/constants/common';
import { sendInteraction, SendInteractionsMessages } from '@/shared/model/interactions';
import { TableIsFull } from '@/entities/notifications/TableIsFull';
const FriendsLoader = observer(({ children }) => {
    const { userRoomQuestionAnswer } = userStore;
    const { friends, invites, newFriends } = friendStore;
    const { pendingInviteFriendIds } = pendingInteractionsStore;
    const navigate = useNavigate();
    const params = useParams();
    const roomId = Number(params.roomId);
    const disconnect = useRoomDisconnect();
    useFetchFriends();
    useNotificationHubReceive(HUB_METHODS.notification.receive.friendsList, (hubMessage) => {
        friendStore.friends = hubMessage.friendsList;
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.newFriendRequest, (hubMessage) => {
        // Check duplicates in friends requests
        // On BE we check it, but anyway for some reason we got duplicates in Requests list :(
        const friendNotExist = friends.length === 0 || !friends.some((friend) => friend.id === hubMessage.data.id);
        if (friendNotExist)
            friendStore.friends = [hubMessage.data, ...friends];
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.newFriendMessage, (message) => {
        const newMessage = {
            id: 1,
            senderId: message.senderId,
            receiverId: message.receiverId,
            message: message.message,
        };
        const { activeChatHistory, activeFriendChatId } = friendStore;
        friendStore.activeChatHistory =
            activeChatHistory.length === 0 ? activeChatHistory : [newMessage, ...activeChatHistory];
        friendStore.friends = friends.map((friend) => {
            if (friend.id !== message.senderId)
                return friend;
            return {
                ...friend,
                lastChatMessage: {
                    senderId: message.senderId,
                    data: message.message,
                    receiverId: message.receiverId,
                    unread: message.senderId !== activeFriendChatId,
                    sentTime: message.sentTime,
                },
            };
        });
        if (message.senderId !== activeFriendChatId) {
            headerStore.chatMessageReceived = true;
            headerStore.increaseUnreadMessageCount(message.senderId);
        }
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.approveFriendRequest, (hubMessage) => {
        const filtered = friends.filter((friend) => friend.id !== hubMessage.data.id);
        pendingInteractionsStore.pendingInviteFriendIds = [
            ...pendingInviteFriendIds.filter((friend) => friend !== hubMessage.data.id),
        ];
        friendStore.friends = [...filtered, hubMessage.data];
        friendStore.newFriends = [
            ...newFriends.filter((friend) => friend.id !== hubMessage.data.id),
            hubMessage.data,
        ];
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.declineFriendRequest, (hubMessage) => {
        const filtered = friends.filter((friend) => friend.id !== hubMessage.data.id);
        pendingInteractionsStore.pendingInviteFriendIds = [
            ...pendingInviteFriendIds.filter((friend) => friend !== hubMessage.data.id),
        ];
        friendStore.friends = filtered;
        // TODO: Show popup about decline, in callback object we have profilePicture and username
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.removed, (hubMessage) => {
        const filtered = friends.filter((friend) => friend.id !== hubMessage.data.id);
        friendStore.newFriends = [...newFriends.filter((friend) => friend.id !== hubMessage.data.id)];
        friendStore.friends = filtered;
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.inviteToTable, (hubMessage) => {
        const inviteExists = invites.some((invite) => invite.inviterId === hubMessage.inviterId);
        if (inviteExists)
            return;
        friendStore.invites = [hubMessage, ...invites];
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.inviteToTableDenied, ({ byUserId }) => {
        friendStore.removePendingInvite(byUserId);
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.inviteToTableCanceled, ({ byUserId }) => {
        friendStore.removePendingInvite(byUserId);
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.approveInviteToTableInviter, ({ byUserId }) => {
        friendStore.removePendingInvite(byUserId);
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.approveInviteToTable, async ({ approved, status, seat }) => {
        if (!approved) {
            const message = getStatusMessage(status);
            modalStore.open({
                content: _jsx(AlertNotification, { message: message }),
            });
            return;
        }
        if (approved && seat) {
            userStore.userTableId = seat.tableC;
            userStore.userTableName = 'Table';
            return;
        }
        if (userStore.userSeat) {
            modalStore.open({
                content: _jsx(TableIsFull, { message: "Your friend's table is already full." }),
            });
            return;
        }
        modalStore.open({
            content: (_jsx(TableIsFull, { message: "Your friend's table is already full, so we've placed you at the bar instead." })),
        });
        friendStore.enterFromInvite = false;
        await connectionStore.interactionHubConnection?.stop();
        connectionStore.interactionHubConnection = null;
        await connectionStore.connectUserToRoom(userStore.user.id, roomId);
        await sendInteraction({
            method: SendInteractionsMessages.sendUpdateStreamId,
            message: {
                streamId: 0,
                answer: userRoomQuestionAnswer,
            },
        });
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.onlineFriendsList, (onlineList) => {
        friendStore.friends = friends.map((friend) => {
            if (onlineList.data.includes(friend.id)) {
                return { ...friend, onlineStatus: true };
            }
            return { ...friend, onlineStatus: false };
        });
    });
    const changeFriendOnlineStatus = useCallback(async (friendId, status) => friends.map((friend) => {
        if (friend.id === friendId) {
            return { ...friend, onlineStatus: status };
        }
        return friend;
    }), [friends]);
    useNotificationHubReceive(HUB_METHODS.notification.receive.friendBecomeOnline, async (onlineFriend) => {
        friendStore.friends = await changeFriendOnlineStatus(onlineFriend.id, true);
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.friendBecomeOffline, async (offlineFriend) => {
        friendStore.friends = await changeFriendOnlineStatus(offlineFriend.id, false);
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.friendPlace, (hubMessage) => {
        friendStore.friends = friends.map((friend) => {
            if (friend.id === hubMessage.friendId) {
                return { ...friend, lastOnlinePlace: hubMessage.name, onlineStatus: true };
            }
            return friend;
        });
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.invites, (hubMessage) => {
        friendStore.invites = hubMessage.invites ?? [];
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.inviterStatus, async ({ approved, status }) => {
        if (!friendStore.invites.length)
            return;
        const [invite, ...other] = friendStore.invites;
        friendStore.invites = other;
        if (!approved) {
            if (status === NotificationHubStatusCode.Canceled) {
                modalStore.open({
                    content: (_jsx(InteractionNotification, { title: 'Invitation cancelled', message: 'Your friend has cancelled the invitation. Be quicker next time or make the first move yourself!', width: '494px' })),
                });
                return;
            }
            const message = getStatusMessage(status);
            modalStore.open({
                content: _jsx(AlertNotification, { message: message }),
            });
            return;
        }
        if (approved) {
            const { roomId: roomJoinId, inviterId } = invite;
            if (roomId !== roomJoinId) {
                await disconnect();
                navigate(`${ROUTES.rooms.path}`);
                StorageService.set(STORAGE_KEYS.enterByInvite, true);
                StorageService.set(STORAGE_KEYS.invitedBy, inviterId);
                friendStore.enterFromInvite = true;
                friendStore.inviteSentById = inviterId;
                navigate(`${ROUTES.rooms.path}/${roomJoinId}`);
                await NotificationHub.sendMessage(HUB_METHODS.notification.send.approveInviteToTable, {
                    roomId,
                    inviterId,
                });
            }
            else {
                await NotificationHub.sendMessage(HUB_METHODS.notification.send.approveInviteToTable, {
                    roomId,
                    inviterId,
                });
            }
        }
    });
    return children;
});
FriendsLoader.displayName = 'FriendsLoader';
export default memo(FriendsLoader);
