import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import { memo } from 'react';
import { ModalContent, CloseButtonWrapper, NotificationContent, ContentActions } from './styled';
import { modalStore } from '@/shared/model/modal';
import TextElement from '@/shared/ui/TextElement';
import CrossIcon from '@/shared/assets/icons/Cross';
import ButtonCustom from '@/shared/ui/ButtonCustom';
const handleClose = () => {
    modalStore.close();
};
const DEFAULT_MESSAGE = 'This table is already full. Looks like a popular spot! Try another table or check back for an open seat here.';
const InnerTableIsFullNotification = ({ message = DEFAULT_MESSAGE }) => (_jsxs(ModalContent, { children: [_jsx(CloseButtonWrapper, { children: _jsx(IconButton, { size: 'medium', "aria-label": 'Close notification', onClick: handleClose, children: _jsx(CrossIcon, {}) }) }), _jsxs(NotificationContent, { children: [_jsx(TextElement, { fontWeight: 600, fontSize: 24, textAlign: 'center', children: "Table Filled Up!" }), _jsx(TextElement, { fontWeight: 400, fontSize: 16, textAlign: 'center', children: message })] }), _jsx(ContentActions, { children: _jsx(ButtonCustom, { variant: 'contained', onClick: handleClose, height: 48, width: 162, children: "Continue" }) })] }));
export const TableIsFull = memo(InnerTableIsFullNotification);
