import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { HttpStatusCode, isAxiosError } from 'axios';
import { RestrictionWrapper, RestrictionText, BackWrapper, Title, Content, Image, RestrictionTextLink, SendedInfoWrapper, SendedInfoContainer, SendedInfoTitleContainer, SendedInfoIcon, SendedInfoDescription, FormWrapper, Input, Button, } from './styled';
import { defaultValues, registerScheme } from './validation';
import { ArrowBackIcon, SmsIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
import { modalStore } from '@/shared/model/modal';
import RouteLink from '@/shared/ui/RouteLink';
import { screamingGuy } from '@/pages/lobby/assets/images';
import AuthService, { ReminderFailReasons, } from '@/shared/api/services/AuthService';
import { CloseButton } from '@/shared/ui/ModalContent';
const MobileRestriction = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isEmailSend, setIsEmailSend] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { handleSubmit, control, formState: { errors, isValid }, } = useForm({
        defaultValues,
        resolver: zodResolver(registerScheme),
    });
    const submit = useCallback(async (data) => {
        if (!isValid)
            return;
        setError('');
        setLoading(true);
        const response = await AuthService.sendReminder({ email: data.email });
        setLoading(false);
        if (response && !isAxiosError(response)) {
            const { data: { success }, } = response;
            if (success) {
                setIsEmailSend(true);
                return;
            }
        }
        if (isAxiosError(response)) {
            const { response: apiResponse, request: { status }, } = response;
            if (status === HttpStatusCode.TooManyRequests) {
                setError('System error');
                return;
            }
            const { data: apiResponseData } = apiResponse;
            const { message } = apiResponseData ?? {};
            if (message === ReminderFailReasons.DAY_LIMIT_EXCEEDED) {
                setError('Resend limit reached. Try again later.');
                return;
            }
        }
        setError('Something went wrong. Try again later.');
    }, [isValid, setError]);
    const handleClose = useCallback(() => {
        modalStore.close();
        if (pathname === ROUTES.signUp.path) {
            navigate(-1);
        }
    }, [navigate, pathname]);
    return (_jsxs(Content, { children: [_jsx(CloseButton, {}), _jsx(Image, { src: screamingGuy }), _jsx(Title, { children: "Realush is Desktop Only" }), _jsxs(RestrictionWrapper, { children: [_jsxs(RestrictionText, { children: ["Great experiences deserve a great screen! Drop your email below, and we\u2019ll send you a reminder to join the fun from your desktop.", _jsx("br", {}), "You can find out more about this", ' ', _jsx(RestrictionTextLink, { href: '#why-desktop-only', onClick: handleClose, children: "here." })] }), !isEmailSend ? (_jsxs(FormWrapper, { onSubmit: handleSubmit(submit), children: [_jsx(Controller, { name: 'email', control: control, render: ({ field: { ref, onChange, ...field } }) => (_jsx(Input, { ...field, fullWidth: true, inputRef: ref, Icon: SmsIcon, onChange: (event) => {
                                        onChange(event);
                                        setError('');
                                    }, error: !!errors.email || !!error, helperText: errors.email?.message ?? error, type: 'email', placeholder: 'Email' })) }), _jsx(Button, { fontWeight: 600, fullWidth: true, loading: loading, height: 48, children: "Send Reminder" })] })) : (_jsx(SendedInfoContainer, { children: _jsxs(SendedInfoWrapper, { children: [_jsxs(SendedInfoTitleContainer, { children: [_jsx(SendedInfoIcon, {}), " Email sent!"] }), _jsx(SendedInfoDescription, { children: "Check your inbox and join the action!" })] }) }))] }), isEmailSend && (_jsx(BackWrapper, { children: _jsxs(RouteLink, { to: ROUTES.home.path, color: 'black', onClick: handleClose, children: [_jsx(ArrowBackIcon, {}), " Back to Site"] }) }))] }));
};
export default memo(MobileRestriction);
