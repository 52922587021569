import styled from 'styled-components';
export const ModalContent = styled.div `
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 494px;
  position: relative;
  gap: 12px;
`;
export const CloseButtonWrapper = styled.div `
  position: absolute;
  display: flex;
  justify-content: right;
  align-items: center;
  right: 16px;
  top: 16px;

  svg {
    color: #aba7af;
    width: 16px;
    height: 16px;

    path {
      stroke-width: 1px;
    }
  }
`;
export const NotificationContent = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
export const ContentActions = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 12px;
  width: 100%;
`;
